import React, { Component } from 'react'

class VendorAgreement extends Component {
  render() {
    return (
      <div className="vendorContent">
        <div align="center">
          <a name="_Hlk523219450" href="">
            <strong>
              <u>VENDORS AGREEMENT</u>
            </strong>
          </a>
        </div>
        <div>
          This Vendors Agreement (hereinafter referred to as the “
          <strong>Agreement</strong>”) is between the registered user (the “{' '}
          <strong>Company</strong>”) and you (if registering as an individual)
          or the entity you represent (if registering as a business) (“Vendor”
          or “You”) as a vendor who access the services provided by the Company
          through the website (hereinafter referred to as “Platform”). The
          “Services” constitute an electronic billing platform which facilitates
          Vendor and customer to submit and receive electronic bills.
          <strong></strong>
        </div>
        <div>
          <strong>1. </strong>
          <strong>Definitions</strong>
        </div>
        <div>
          1.1. “<strong>Customer</strong>” shall mean any person (including a
          company, partnership firm or any other legal entity) having accepted
          the terms and conditions contained herein and using the Platform and
          Services provided in its internal business operations and in
          accordance with applicable law and the terms and conditions contained
          herein.
        </div>
        <div>
          1.2. “<strong>Bill</strong>” shall mean invoices raised electronically
          by the Vendor on the Customer for using/utilizing the goods or
          services provided by the Vendor.
        </div>
        <div>
          <strong>2. </strong>
          <strong>Grant of Services</strong>
        </div>
        <div>
          Subject to the Vendor accessing the Services, the Company hereby
          grants to the Vendor a non-exclusive, non-transferable right to permit
          the use of the Services during the Term solely for the Vendor's
          internal business operations.<strong></strong>
        </div>
        <div>
          <strong>3. </strong>
          <strong>Vendor Account</strong>
        </div>
        <div>
          3.1. Account creation requires Vendor to provide an email address and
          password and certain other personal information; additionally, Vendor
          may furnish other information such as Vendor’s username created,
          location, company profile, and all other relevant information
          (collectively, “ <strong>Data</strong>”) as provided for Vendor
          Subscription as part of the Vendor’s use of Services you should (a)
          Provide true, accurate, current, and complete information about the
          Vendor as prompted during the account creation/registration process,
          and (b) Maintain and promptly update Vendor’s Data to keep it accurate
          and current. Vendor agrees that the Company may use Vendor’s Data to
          provide Vendor Services, if any information that is inaccurate or not
          current, some of the Services may not operate correctly. Additionally,
          if Vendor provides any information for the purposes of fraudulent or
          criminal activities, or the Company have reasonable grounds to suspect
          that such information has been provided, the Company have the right to
          suspend or terminate Vendor’s account and refuse any and all current
          or future use of the Services.
        </div>
        <div>
          3.2. By accepting the terms, the vendor grants JHKP TECHNOLOGIES PVT
          LTD with a limited, non-transferrable right to display the vendors
          company names, trademarks™ or registered® trademarks (submitted on the
          registration form) on the Billme app, website and other media
          resources for representational and Electronic bill delivery services
          to its end users. JHKP TECHNOLOGIES PVT LTD does not stake claim to
          third party company names, trademarks™ or registered® trademarks,
          these are used for representational and transactional purposes only.
        </div>
        <div>
          3.3. The Company will not be liable for any fault in the Services due
          to the lack of incorrect, false information provided by the Vendor.
        </div>
        <div>
          3.4. The Company will not be responsible to the Vendor for any loss
          that the Vendor suffer as a result of an unauthorized person accessing
          the Vendor’s account and/or using the Services of Company and Company
          accept no responsibility for any losses or harm resulting from its
          unauthorized use, whether fraudulently or otherwise.
        </div>
        <div>
          3.5. The information provided by Vendor can be also shared with the
          Company’s users, associates and affiliates or other successor in the
          event of a merger, divestiture, restructuring, reorganization,
          dissolution or other sale or transfer of some or all of the Company’s
          assets, whether as a going concern or as part of bankruptcy,
          liquidation or similar proceeding, in which personal information held
          by the Company about the users of the Company’s Services are among the
          assets transferred.
        </div>
        <div>
          3.6. Data may also be shared if the Company believes, in good faith,
          that such sharing is reasonably necessary in order to investigate,
          prevent, or take action regarding possible illegal activities, risk
          aversion or to comply with the law.
        </div>
        <div>
          <strong>4. </strong>
          <strong>Description of Services</strong>
        </div>
        <div>
          4.1. The Company aims at providing an electronic billing platform
          which facilitates the Vendor and Customer to submit and receive
          electronic Bills respectively. Both Vendor and Customer have the
          provision to store the Bill within the Platform operated by the
          Company.
        </div>
        <div>
          4.2. Vendor raises a Bill to the Customer (hereinafter referred to as
          the “ <strong>Vendor Copy</strong>”) and the Customer maintains the
          copy of that Bill (hereinafter referred to as the “
          <strong>Customer Copy</strong>”). Both Vendor and Customer possess the
          sole ownership of the Vendor Copy and Customer Copy respectively.
        </div>
        <div>
          <a name="OLE_LINK2" href="">
            4.3. The Company does not claim any ownership of the Bills.
          </a>
          Vendor has the authority to make deletions to the Vendor Copy as and
          when the Vendor finds it necessary to do so.{' '}
          <a name="OLE_LINK3" href="">
            <strong></strong>
          </a>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong>5. </strong>
          <strong>Restrictions</strong>
        </div>
        <div>
          5.1. The Vendor must not use the Service to create, transmit, display
          or otherwise make available any material that:
        </div>
        <div>
          (a) violates the terms of this Agreement or the rights of the Company
          and Customers,
        </div>
        <div>
          (b) is harmful (for example viruses, worms, malware and other
          destructive codes),
        </div>
        <div>
          (c) is offensive, threatening, abusive, harassing, tortuous,
          defamatory, vulgar, pornographic, obscene, invasive of another’s
          privacy, defamatory, hateful or otherwise unlawful.
        </div>
        <div>5.2. The Vendor must not:</div>
        <div>
          (a) sell, resell, license, sublicense, distribute or otherwise
          commercially exploit the Service and/or the Platform in the interests
          of any third party;
        </div>
        <div>(b) attempt to gain unauthorized access to the Service;</div>
        <div>
          (c) interfere with and/or disturb the integrity of the Service, or
          data therein;
        </div>
        <div>(d) attempt to modify the Service of the Company;</div>
        <div>
          (e) access the Platform to monitor the availability, performance,
          functionality thereof or to benchmark or use the Platform for
          competitive purposes;
        </div>
        <div>
          (f) share any data or content from the Service or the Platform with
          the competitors of the Company;
        </div>
        <div>
          (g) send communications containing recruitment or marketing content to
          other Vendor and/or their respective Vendor accounts, using data,
          content or information obtained through the Platform.
        </div>
        <div>
          5.3. The Vendor’s account must not be shared or used by more than one
          individual user but may be reassigned from time to time to new users
          who are replacing former users who have terminated employment or
          otherwise changed job status or function and no longer need to use the
          Services.
        </div>
        <div>
          <strong>6. </strong>
          <strong>Subscription Fee</strong>
        </div>
        <div>
          6.1. BillMe bill delivery services for vendors is free for use. The
          Company reserves the right to revise the Subscription Fee at its own
          discretion and the same shall be notified to the Vendor
          <strong>.</strong>
        </div>
        <div>
          <strong>7. </strong>
          <strong>Term</strong>
        </div>
        <div>
          This Agreement shall take effect upon the Effective Date and, shall
          continue to remain in force for a period of 12 (twelve) months (the “{' '}
          <strong>Initial Term</strong>”) and thereafter this Agreement shall be
          automatically renewed for successive period of 12 (twelve) months each
          (the “<strong>Renewal Period</strong>”), unless terminated as follows:{' '}
          <strong></strong>
        </div>
        <div>
          (a) Either Party notifies the other party of termination, in writing,
          at least 30 (thirty) days before the end of the Initial Term or
          beginning of any Renewal Period, in which case this Agreement shall
          terminate upon the expiry of the Initial Term or Renewal Period; or
        </div>
        <div>
          (b) As otherwise terminated in accordance with the provisions of this
          Agreement;
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong>8. </strong>
          <strong>Termination</strong>
        </div>
        <div>
          8.1. The company may terminate the agreement via request to delete its
          account &amp; data from BillMe services, after handing over the
          respective vendors data. JHKP Technologies Private Limited may
          terminate this Agreement via email, or if prior to such action, the
          other party materially breaches any of its representations, warranties
          or obligations under this Agreement.
        </div>
        <div>
          <strong>9. </strong>
          <strong>Representations and Warranties</strong>
        </div>
        <div>
          9.1. The Parties each represent and warrant that:<strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          (a) Each Party has the competence, authority, and right to perform its
          obligations under this Agreement.<strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          (b) This Agreement constitutes a legal, valid, and binding obligation
          on each Party, enforceable against such Party in accordance with its
          terms (except as may be limited by winding up, insolvency, moratorium,
          or similar laws affecting secured creditors’ rights generally and
          equitable remedies). <strong></strong>
        </div>
        <div>
          (c) Entering into this Agreement will not violate the charter or
          bylaws of either Party or any material contract to which that Party is
          also a party. <strong></strong>
        </div>
        <div>
          <strong> </strong>
        </div>
        <div>9.2. The Company represents and warrants as follows:</div>
        <div>
          (a) All analytics generated and/or collected through the use of the
          Service is owned by the Company and forms a part of the Company’s
          intellectual property and is managed according to the privacy policy
          of the Company;
        </div>
        <div>
          (b) The Company has or has access to all necessary know-how,
          expertise, and experience to perform its obligations under this
          Agreement;
        </div>
        <div>
          (c) The Service will incorporate security features reflecting the
          requirements of good industry practice.
        </div>
        <div>9.3. The Vendor represents and warrants as follows:</div>
        <div>
          (a) Vendor shall use the Services only for lawful purposes. To the
          extent deemed necessary by Vendor, Vendor shall implement security
          procedures necessary to limit access to the Services to Vendor’s
          authorized users and shall maintain a procedure external to the
          Services for reconstruction of lost or altered files, data or
          programs;
        </div>
        <div>
          (b) Vendor is responsible for establishing designated points of
          contact to interface with the Company;
        </div>
        <div>
          (c) Vendor will not disrupt a third parties’ similar use of Services
          or licensed materials;
        </div>
        <div>
          (d) Vendor will ensure certain marketing, operational support to the
          Company;
        </div>
        <div>
          (e) To not violate or tamper with the security of any programs, and
          electronic billing systems put in place by the Company;
        </div>
        <div>
          (f) Vendor shall also be responsible for maintaining the security of
          the Vendor account, passwords (including but not limited to
          administrative and user passwords) and files, and for all uses of
          Vendor account with or without Vendor’s knowledge or consent;
        </div>
        <div>
          (g) Vendor acknowledges that the Services provided by the Company
          under this Agreement is for the limited purpose of operations of the
          Service;
        </div>
        <div>
          (h) Vendor will not conduct any systematic or automated data scraping,
          data mining, data extraction or data harvesting, or other systematic
          or automated data collection activity, by means of or in relation to
          the Services;
        </div>
        <div>
          (i) That any modification to the Services performed by the Vendor
          directly or indirectly extending the current capabilities shall be the
          property of the Company and all copyrights and other rights are hereby
          assigned to the Company;
        </div>
        <div>
          (j) The Vendor undertakes that he is fully functional and eligible to
          carry out the functions expected out of him for enlisting on the
          Platform;
        </div>
        <div>
          (k) The Vendor shall at all time during the pendency of this Agreement
          endeavor to protect and promote the interests of the Company and
          ensure that third parties rights including Intellectual Property
          Rights are not infringed;
        </div>
        <div>
          (l) To provide to the Company copies of any document required by the
          Company for the purposes of performance of its obligations under this
          Agreement;
        </div>
        <div>
          (m) The Vendor agrees to replace the Bills which were wrongly raised
          to the Customer and the Company shall not be held responsible for such
          erroneous actions of the Vendor;
        </div>
        <div>
          (n) Not to do any act/deal in any products/goods/services, which are
          either banned/prohibited by law or violates any law applicable;
        </div>
        <div>
          (o) The Vendor to provide full, complete, accurate and true correct
          Bill to the Customer and further agrees not to provide any such
          information which amounts to misrepresentation to the Customer;
        </div>
        <div>
          (p) The Vendor shall offer all the promotional schemes provided to the
          Customer time to time on the Platform;
        </div>
        <div>
          (q) The Vendor shall not send any kind of promotion material or any
          such material, which is, derogatory to and/or adverse to the interests
          financial or otherwise of the Company, to the Customer in any manner
          whatsoever;
        </div>
        <div>
          (r) If Vendor is a business or other legal entity and not an
          individual, then the individual entering into this Agreement on
          Vendor's behalf represents that he or she has all necessary legal
          authority to bind Vendor to this Agreement;
        </div>
        <div>
          (s) The Vendor has the full right, power, and authority to enter into
          and fully perform this Agreement;
        </div>
        <div>
          <strong>10. </strong>
          <strong>Confidentiality</strong>
        </div>
        <div>
          10.1. Both Parties agree and undertake that during the term of this
          Agreement and thereafter they will keep confidential and, save as
          expressly provided in this Agreement, shall not without the prior
          consent of the other Party disclose to any third party any and all
          information of the other Party which is of a confidential nature and
          which has been disclosed by one Party to the other during the course
          of their relationship as regulated by the terms of this Agreement.
        </div>
        <div>
          10.2. However, there are certain exemptions to the provisions of this
          Clause and this Clause shall not apply to any information which:
        </div>
        <div>
          (a) is published or comes into the public domain other than by a
          breach of this Agreement; or
        </div>
        <div>
          (b) can be shown to have been in the possession of a Party prior to
          the commencement of the negotiations leading to this Agreement with no
          obligation to keep the same in confidence; or
        </div>
        <div>
          (c) is lawfully obtained from a third party with no obligation to keep
          the same in confidence; or
        </div>
        <div>
          (d) is ordered or requested by a court, governmental or regulatory
          authority or tribunal of competent jurisdiction to be disclosed; or
        </div>
        <div>
          (e) is disclosed to a Party's affiliates, or to its professional
          advisers on a need-to-know basis, and in each case under obligations
          of confidentiality at least as onerous as those set out in this
          Agreement.
        </div>
        <div>
          <strong>11. </strong>
          <strong>Indemnity and Limited Liability</strong>
        </div>
        <div>
          11.1. The Vendor agrees to defend, indemnify and hold the Company, and
          it’s respective directors, officers, employees and agents, harmless
          from any claims, losses, damages, liabilities, including attorney’s
          fees, arising out of your use or misuse of the Platform, Services, or
          features made available in conjunction with or through Platform,
          representations made to the Company, its affiliates and/or third
          parties, violation of this Agreement, violation of the rights of any
          other person or entity, or any breach of the foregoing
          representations, warranties, and covenants. The Company reserves the
          right, at its own expense, to assume the exclusive defense and control
          of any matter for which Vendor is required to indemnify the Company,
          and Vendor agrees to cooperate with such defense of these claims.
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          11.2. Neither the Vendor nor the Company shall have any liability to
          the other for any lost profits or for any indirect, special,
          incidental, punitive, or consequential damages, however, caused and,
          whether in contract, tort or under any other theory of liability, even
          if the Vendor or the Company has been advised of the possibility of
          such damages <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong>12. </strong>
          <strong>Disclaimer</strong>
        </div>
        <div>
          12.1. The Company is not responsible for security, integrity or
          privacy of data related to the Customer to the extent to which the
          data of the Customer is transferred outside the scope of Services
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong>13. </strong>
          <strong>Dispute Resolution</strong>
        </div>
        <div>
          If any dispute arises between the Parties in connection with the
          validity, interpretation, implementation or alleged breach of any
          provision of this Agreement and continues to be unresolved for 15
          (fifteen) days, such dispute shall be resolved by a sole arbitrator in
          accordance with the Arbitration and Conciliation Act, 1996 and the
          rules made thereto. The venue of arbitration shall be Mumbai, India.
          The Parties shall appoint a mutually agreed upon sole arbitrator. The
          award of the arbitrator shall be final and binding upon the Parties.
        </div>
        <div>
          <strong>14. </strong>
          <strong>Miscellaneous</strong>
        </div>
        <div>
          14.1. In the performance of this Agreement, both Parties are acting on
          principal to principal basis, independent of each other. None of the
          employee, officials, agents or assigns of a Party can be treated as an
          agent of the other Party and in no case can bind the other Party by
          its representations and acts.<strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          14.2. The Company may assign any or all of its rights and/or
          obligations arising out of or in connection with this Agreement
          freely, in whole or in part. <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          14.3. This Agreement is subject to force majeure situations. It shall
          be subject to inabilities based on circumstances beyond the power of
          the Agreement, such as civil commotion, riots, strike, lockouts and
          acts of God etc.<strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          14.4. The rights and obligations of the Parties under, or pursuant to,
          this Agreement, including this clause, shall be under the exclusive
          jurisdiction of the courts located at Mumbai and the governing law
          shall be the laws of India. <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          14.5. This Agreement sets forth the entire agreement and understanding
          between the Parties as to the subject matter of this Agreement and
          supersedes all prior or simultaneous representations, discussions, and
          negotiations whether oral or written. This Agreement shall be amended
          or supplemented by the Company and the same shall be notified to the
          Vendor within 10 (ten) days of such amendment or supplementation.{' '}
          <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          14.6. No term or provision hereof will be considered waived and no
          breach excused by either Party, unless such waiver or consent is in
          writing and duly signed. No consent of waiver or a breach by a Party
          will constitute consent to the waiver of or excuse of any different or
          subsequent breach by the recipient. <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          14.7. Any notice shall be deemed given on the day of mailing or, if
          notice is by post, e-mail, or fax, on the next day following the day
          notice is deposited with the courier company for shipment or e-mailed
          or faxed. For the purpose of notice to be given to the Vendor, the
          address furnished by the Vendor at the event of registration shall be
          used.<strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
        <div>
          <strong></strong>
        </div>
      </div>
    )
  }
}

export default VendorAgreement
