import React, { Component } from 'react'

class CompanyCard extends Component {
  constructor(props) {
    super(props)
    this.onClickBox = this.onClickBox.bind(this)
  }
  onClickBox() {
    this.props.onClick && this.props.onClick(this.props.number)
  }
  render() {
    return (
      <div
        className="profileInnerBox shadow p-1 mb-1 bg-white rounded"
        onClick={this.onClickBox}
      >
        <div className="companyName">{this.props.companyName}</div>
        <div className="companyEmail">Email: {this.props.companyEmail}</div>
        <div className="companyContact">
          Contact: {this.props.companyContact}
        </div>
        <div className="companyContact companyBalance">
          Balance: {this.props.companyBalance} Rs.
        </div>
      </div>
    )
  }
}

export default CompanyCard
