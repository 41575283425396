export function toggleMenu(toggleStatus) {
  return {
    type: 'toggleMenu',
    payload: toggleStatus,
  }
}

export function makeBack(location) {
  return {
    type: 'makeBackButton',
    payload: location,
  }
}
