import React, { Component } from 'react'

class AdvertList extends Component {
  constructor(props) {
    super(props)
    this.onClickRow = this.onClickRow.bind(this)
  }
  onClickRow() {
    this.props.onView(
      this.props.campaignName,
      this.props.campaignId,
      this.props.adRedirectUrl,
      this.props.status
    )
  }
  render() {
    return (
      <tr onClick={this.onClickRow}>
        <td>{this.props.srNo}</td>
        <td className="listCampaignName">{this.props.campaignName}</td>
        <td className="redirectionUrl">
          {this.props.adRedirectUrl ? this.props.adRedirectUrl : '-'}
        </td>
        <td>{this.props.campaignDate}</td>
        {/* <td className="radioActive">
          <input
            type="radio"
            className="form-control advertRadioButton"
            disabled
            checked={this.props.status}
          />
          {this.props.status ? (
            <button className="advertActiveButton" disabled>
              Active
            </button>
          ) : null}
        </td> */}
      </tr>
    )
  }
}

export default AdvertList
