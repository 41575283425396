const initialState = {
  usage: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_USAGELIST':
      return {
        usage: action.payload,
      }
    default:
      return state
  }
}
