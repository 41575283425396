import React, { Component } from 'react'

class PrivacyContent extends Component {
  render() {
    return (
      <div className="privacyContent">
        <div align="center">
          <p name="_Hlk519531001" style={{ color: '#000' }}>
            <strong>
              <u>PRIVACY POLICY</u>
            </strong>
          </p>
        </div>
        <div>
          Billme is a website (URL:billme.co.in) (hereinafter referred to as
          “Platform”) assists in converting traditional billing and payment
          system to a paperless and automated solution, is owned and operated by
          JHKP TECHNOLOGIES PRIVATE LIMITED (“Company” or “We” and their
          connotations). We recognize the privacy concerns of its Users and
          their guests (hereinafter collectively referred to as the “Users”
          and/or “You” and their connotations) and hence as a commitment to
          provide safe and secure experience on our Platform, We have formulated
          this privacy policy to help Users make an informed decision.
        </div>
        <div>
          This Privacy Policy (“Privacy Policy”) is meant to help you understand
          what information we collect, why we collect it, and how you can
          update, manage, export, and delete your information while You access
          to the Platform (hereinafter referred to as “Services”).
        </div>
        <div>
          If you do not agree with this Policy, do not use our Services or
          provide here with your personal data.
        </div>
        <div>
          <strong>1. </strong>
          <strong>
            <u>Information we Collect</u>
          </strong>
        </div>
        <div>
          In order to provide Services, we require certain user information and
          may obtain the same through user-provided data or automatic data
          collection.
        </div>
        <div>
          a. We collect information that may be used to identify you (“Personal
          Information”) including, but not limited to, (i) name, e-mail ID,
          photograph, location, (ii) age, gender, and other demographics. We do
          not consider Personal Information to include information that has been
          made anonymous or aggregated so that it can no longer be used to
          identify a specific person, whether in combination with other
          information or otherwise.
        </div>
        <div>
          b. We also collect certain information either when you provide it
          using the Platform or Services and / or automatically as you navigate
          through the Platform (which may include usage details, IP address,
          device ID and type, your browser type and language, the operating
          system used by your device, access times, and information collected
          through cookies, web beacons and other tracking technologies). You
          agree that we may use such information to do internal research on our
          Users' demographics to better understand, protect and serve our Users.
        </div>
        <div>
          c. We also collect information from your user ID associated with any
          social media account (such as your Facebook and Google account) that
          users use to sign into the Platform or connect with or use of our
          Services. When you sign in with your social media account information,
          or otherwise connect to your social media account with the Platform,
          you consent to our collection, storage, and use, in accordance with
          this Privacy Policy, of the information that you make available to us
          through the social media interface. This includes, without limitation,
          any information that you have made public through your social media
          account, information that the social media service shares with us or
          information that is disclosed during the sign-up and sign-in
          processes.
        </div>
        <div>
          d. If another User allows us to collect information from his or her
          device phonebook—and you are one of that User’s contacts—we may
          combine the information we collect from that User’s phonebook with
          other information we have collected about you. We may also obtain
          information from other sources and combine that with the information
          we collect through the Platform.
        </div>
        <div>
          e. We use data collection devices such as "cookies" on certain pages
          of the Platform to help analyze our web page flow, measure promotional
          effectiveness, and promote trust and safety. Cookies are small files
          placed on your hard drive that assist us in providing our Services. We
          offer certain features that are only available using a cookie. Cookies
          can also help us provide information that is targeted to your
          interests.
        </div>
        <div>
          f. We may process information relating to transactions, including
          purchases of goods and services, which you enter with us and/or
          through our Platform. The transaction data may include your contact
          details, your card details, and the transaction details. The
          transaction data may be processed for supplying the purchased goods
          and services and keeping proper records of those transactions.
        </div>
        <div>
          g. User shall grant to the Company a non-exclusive, transferable,
          sub-licensable, royalty-free, worldwide license for content that is
          covered by intellectual property rights, like photos and videos (IP
          License). This IP License shall end when you delete your IP content or
          your account unless your content has been shared with others, and they
          have not deleted it. When you delete IP content, it is deleted in a
          manner similar to emptying the recycle bin on a computer. However, you
          understand that removed content may persist in backup copies for a
          reasonable period (but will not be available to others).
        </div>
        <div>Additionally,</div>
        <div>
          h. We reserve the right to use or disclose your Personal Information
          in response to subpoenas, court orders, warrants, or legal process, or
          to otherwise establish or exercise our legal rights or defend against
          legal claims or in the event you violate or breach an agreement with
          us. We will use and disclose your Personal Information if we believe
          you will harm the property or rights of the Platform, its owners, or
          those of the Platform’s other users. Finally, we will use or disclose
          your Personal Information if we believe it is necessary to share
          information in order to investigate, prevent, or take action regarding
          illegal activities, suspected fraud, situations involving potential
          threats to the physical safety of any person or property, violations
          of the Platform’s other policies, or as otherwise required by law when
          responding to subpoenas, court orders and other legal processes.
        </div>
        <div>
          i. If we believe that disclosure is necessary or appropriate to
          protect our rights, property, or safety, our Users or others, we may
          in good faith share information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </div>
        <div>
          j. You agree that we may use Personal Information about you to improve
          our marketing and promotional efforts, to analyze site usage, improve
          the Platform's content and service offerings, and customize the
          Platform's content, layout, and services. These uses improve the
          Platform and better tailor it to meet your needs, so as to provide you
          with an efficient, safe and customized experience while using the
          Platform.
        </div>
        <div>
          <strong>2. </strong>
          <strong>
            <u>Application of User Information</u>
          </strong>
        </div>
        <div>
          We may use information about you for several purposes, including:
        </div>
        <div>a. Providing, Improving, and Developing our Services</div>
        <div>
          (i) Determining whether the Services are available in your country;
        </div>
        <div>
          (ii) Processing or recording payment transactions or money transfers;
        </div>
        <div>
          (iii) Providing you with our products and features you choose to use;
        </div>
        <div>
          (iv) Displaying your historical transaction or appointment
          information;
        </div>
        <div>(v) Providing, maintaining and improving our Services;</div>
        <div>(vi) Developing new products and services;</div>
        <div>
          (vii) Delivering the information and support on your request,
          including technical notices, security alerts, and support and
          administrative messages including to resolve disputes, collect fees,
          and provide assistance for problems with our Services or your account;
        </div>
        <div>
          (viii) Improving, personalizing, and facilitating your use of our
          Services;
        </div>
        <div>
          (ix) Measuring, tracking, and analyzing trends and usage in connection
          with your use or the performance of our Services.
        </div>
        <div>b. Communicating with You about our Services</div>
        <div>
          (i) Sending you information we think you may find useful or which you
          have requested from us about our products and services;
        </div>
        <div>
          (ii) Conducting surveys and collecting feedback about our Services.
        </div>
        <div>
          c. Protecting our Services and maintaining a trusted environment
        </div>
        <div>
          (i) Investigating, detecting, preventing, or reporting fraud,
          misrepresentations, security breaches or incidents, other potentially
          prohibited or illegal activities, or to otherwise help protect your
          account;
        </div>
        <div>
          (ii) Protecting our rights or property, as well as the rights or
          properties of your customers’, our customers’, or the security or
          integrity of our Services;
        </div>
        <div>
          (iii) Enforcing our terms and conditions or other applicable
          agreements or policies;
        </div>
        <div>(iv) Verifying your identity;</div>
        <div>
          (v) Complying with any applicable laws or regulations, or in response
          to lawful requests for information from the government or through
          legal process;
        </div>
        <div>
          (vi) Fulfilling any other purpose disclosed to you in connection with
          our Services;
        </div>
        <div>
          (vii) Contacting you to resolve disputes, collect fees, and aid with
          our Services.
        </div>
        <div>d. Advertising and Marketing</div>
        <div>(i) Marketing of our Services;</div>
        <div>
          (ii) Communicating with you about opportunities, products, services,
          contests, promotions, discounts, incentives, surveys, and rewards
          offered by us and select partners;
        </div>
        <div>
          (iii) If we send you marketing emails, each email will contain
          instructions permitting you to “opt out” of receiving future marketing
          or other communications.
        </div>
        <div>e. Other Uses</div>
        <div>
          (i) For any other purpose disclosed to you in connection with our
          Services from time to time.
        </div>
        <div>
          <strong>
            <u></u>
          </strong>
        </div>
        <div>
          <strong>3. </strong>
          <strong>
            <u>Review &amp; Alteration of Information</u>
          </strong>
        </div>
        <div>
          We strive to accurately record all information provided on the
          Platform from time to time.
        </div>
        <div>
          a. We encourage you to review, correct, update, and/or change your
          account information or Personal Information at any time.
        </div>
        <div>
          b. You may also delete your information at any time on the Platform.
          However, we may retain certain information as required by law or for
          reasonable business purposes.
        </div>
        <div>
          c. You have a right to review, update, correct, and delete your
          information; subject to our records retention policies and applicable
          law, including any statutory retention requirements.
        </div>
        <div>
          d. We reserve the right to delete your account if we find that the
          personal information about you that is inaccurate, incomplete, or
          irrelevant for legitimate purposes, or are being processed in a way
          that infringes any applicable legal requirement
        </div>
        <div>
          <strong>4. </strong>
          <strong>
            <u>Third-Party Services</u>
          </strong>
        </div>
        <div>
          Our Platform may contain links to other websites. Please note that
          when you click on one of these links, you are entering another website
          over which we have no control and for which we will bear no
          responsibility. Often these websites require you to enter your
          Personal Information. We encourage you to read the privacy policies of
          all such websites as their policies may differ from ours. You agree
          that we shall not be liable for any breach of your privacy of Personal
          Information or loss incurred by your use of such websites or services.
        </div>
        <div>
          <strong>5. </strong>
          <strong>
            <u>Confidentiality</u>
          </strong>
        </div>
        <div>
          We treat your access to Company’s Services with the utmost respect for
          your privacy. Except for anything not disclosed above, we keep your
          information disclosed to the Listed Professional confidential, keeping
          only non-decimated "file" copies for reference in case of legal
          dispute. We shall use commercially reasonable efforts to protect and
          secure the same. However, we shall not be liable for any
          damage/loss/exposure caused to the information collected by us.
        </div>
        <div>
          <strong>6. </strong>
          <strong>
            <u>Compliance with Data Protection Regulations</u>
          </strong>
        </div>
        <div>
          The information we obtain from or about you may be processed and
          stored in India and our other various servers located across the
          globe, which may provide for different data protection rules than the
          country in which you reside. We comply with generally accepted
          industry standard regulations regarding the collection, use, and
          retention of data. By using the Platform and/or Services, you consent
          to the collection, transfer, use, storage and disclosure of your
          information as described in this Privacy Policy, including to the
          transfer of your information outside the country of your residence
        </div>
        <div>
          <strong>7. </strong>
          <strong>
            <u>Measures that are taken to Protect Your Information</u>
          </strong>
        </div>
        <div>
          We have implemented appropriate physical, electronic, and managerial
          procedures to safeguard and help prevent unauthorized access to your
          information and to maintain data security. These safeguards consider
          the sensitivity of the information that we collect, process and store
          and the current state of technology. We store and process your
          information on reputed web service cloud servers. We impose
          contractual obligation if in any case, we outsource the task of data
          processing. We follow generally accepted industry standards to protect
          your information submitted to us, both during transmission and once
          received, including the use of vault and tokenization services from
          third-party service providers.
        </div>
        <div>
          While we strive to use commercially acceptable means to protect your
          information, we cannot guarantee its absolute security and thereby
          usage in a manner that is inconsistent with this Privacy Policy.
        </div>
        <div>
          We neither accept nor assumes any liability or responsibility for
          disclosure of your information due to errors in transmission,
          unauthorized third-party access, or other causes beyond we control. We
          do not, under any circumstances, represent that our Platform is
          completely void of malware including but not limited to viruses,
          spyware, cancelbots, trojans, time bombs and any such application,
          which may be placed on our Platform without our knowledge. However, we
          shall take all the necessary and reasonable steps to ensure that our
          Platform is free from the above-mentioned malware.
        </div>
        <div>
          You also play a key role in keeping your information secure. Using
          unsecured Wi-Fi or other unprotected networks to submit messages
          through the Platform is never recommended. You should not share your
          username, password, or other security information for your account or
          the device through which you access our Platform and/or Services (as
          the case may be) with anyone. If we receive any instructions through
          an account or device or any other medium bearing your information, we
          will consider that you have authorized the instructions. Please
          remember that if you post any of your Personal Information in public
          areas of the Platform such as in online forums or chat rooms, or in
          the Platform's searchable database, or share such information with
          anybody else, such information may be collected and used by others
          over whom we have no control. We are not responsible for the use made
          by third-parties of information you post or otherwise make available
          in public areas of Platform or share with any other person.
        </div>
        <div>
          <strong>8. </strong>
          <strong>
            <u>In the Event of Change of Control</u>
          </strong>
        </div>
        <div>
          In the event of the change of control of the Company due to the merger
          with a third party, acquisition, bankruptcy or other change of
          control, we reserve the right to transfer or assign the information we
          have collected from our users as part of such merger, acquisition,
          sale, or other change of control. In the unlikely event of our
          bankruptcy, insolvency, reorganization, receivership, or assignment
          for the benefit of creditors, or the application of laws or equitable
          principles affecting creditors' rights generally, we may not be able
          to control how your Personal Information is treated, transferred, or
          used.
        </div>
        <div>
          <strong>9. </strong>
          <strong>
            <u>Changes to this Privacy Policy</u>
          </strong>
        </div>
        <div>
          We reserve the right to update, change or modify this Privacy Policy
          at any time to reflect any (including but not limited to) changes in
          the law, the data collection, and practices, the features of the
          Platform or Services, or advances in technology. The amendment to this
          Privacy Policy shall come to effect from the time of such update,
          change or modification and the same will be published here.
        </div>
        <div>
          You are requested to review the Privacy Policy carefully from time to
          time. Use of information collected is subject to the Privacy Policy in
          effect at the time such information is used. The changes to this
          Privacy Policy shall be treated as read, recognized, understood,
          consented and accepted if you continue to use the Platform or Services
          post such changes.
        </div>
        <div>
          <strong>10. </strong>
          <strong>
            <u>Governing Laws and Dispute Resolution</u>
          </strong>
        </div>
        <div>
          This Privacy Policy shall be governed by and construed in accordance
          with the Arbitration and Conciliation Act 1996, or such similar laws
          of India which are not in conflict with each other. Such shall be
          subjected to the exclusive jurisdiction of the competent courts of
          Mumbai, India.
        </div>
        <div>
          <strong>11. </strong>
          <strong>
            <u>Contact Details</u>
          </strong>
        </div>
        <div>
          If you have any questions about this Privacy Policy, please contact us
          by email or postal mail on the following address:
        </div>
        <div>Name: JHKP TECNOLOGIES PRIVATE LIMITED</div>
        <div>E-mail id: info@billme.co.in</div>
        <div>Place: Navi Mumbai</div>
        <div>Website URL: billme.co.in</div>
      </div>
    )
  }
}

export default PrivacyContent
