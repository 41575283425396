import styled from 'styled-components'
import { CloseButton } from '../components/button'

export const StyledCampaignModal = styled.div`
  background-color: ${(props) => props.theme.colors.neutral['300']};
  padding: 2em 3em;
`

export const Footer = styled.div`
  background-color: ${(props) => props.theme.colors.neutral['100']};
  box-shadow: 0px -3px 2px 0px ${(props) => props.theme.colors.neutral['200']};
  padding: 1em 20px;
  text-align: right;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`

export const CampaignBrand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 145px;
  background-color: ${(props) => props.theme.colors.neutral['100']};
  margin: 10px 0px 4px 0px;
  position: relative;
  border: ${(props) =>
    props.isChecked
      ? '1px solid hsl(217, 100%, 62%)'
      : `1px solid ${props.theme.colors.neutral['200']}`};
  border-radius: 3px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 2px 10px 5px ${(props) => props.theme.colors.neutral['150']};
  }

  & > * {
    cursor: pointer;
  }

  p {
    color: ${(props) => props.theme.colors.neutral['900']};
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 10;
    margin-bottom: 0px;
  }

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
  }

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }

  /* FIXME: Not working, check why cant you target the parent div */
  /* input[type=checkbox]:hover &{
        border: 1px solid red;
        background-color: red;
    } */

  input[type='radio']:checked & {
    border: 1px solid red;
    background-color: red;
    opacity: 0;
  }

  input[type='radio']:hover & {
    border: 1px solid red;
    background-color: red;
  }

  img#checked {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  img,
  section {
    margin-bottom: 1em;
    border-radius: 50%;
  }
  section {
    width: 50px;
    height: 50px;
    background: #eee;
  }
`

export const CampaignNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5em 1em;

  p {
    color: ${(props) => props.theme.colors.neutral['900']};
    text-align: left;
    font-size: 1em;

    span {
      font-size: 0.8em;
    }
  }

  & > img {
    margin-right: 30px;
  }

  section {
    width: 300px;
    height: auto;
    padding: 10px 15px;
    margin-right: 70px;
    box-shadow: 0px 2px 10px 10px
      ${(props) => props.theme.colors.neutral['150']};

    & > div {
      margin-bottom: 1.5em;

      & > div {
        position: relative;
      }

      img {
        margin-right: 10px;
      }
    }
  }
`

export const Pill = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 2.5em 0px 10px;
  border: 1px solid #cfcfcf;
  border-radius: 20px;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
  margin: 0px 5px 3px 5px;

  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 50%;
  }

  ${CloseButton} {
    position: absolute;
    right: 5px;
    top: 12px;
    transform: scale(0.8);
  }
`
