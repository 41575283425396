import React from 'react'

const BillDetailsRight = ({ billLink }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: `0px 8px`,
        boxSizing: 'border-box',
      }}
    >
      <iframe
        src={billLink}
        style={{ width: '100%', height: '100%', borderColor: 'transparent' }}
      />
    </div>
  )
}

export default BillDetailsRight
