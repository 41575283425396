import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { useStoreFetch, useSelectStore } from 'hooks'
import Histogram from 'components/Common/Histogram'
import { Skeleton, SectionHeading, Text } from 'components/Common'
import { NewStarIcon } from 'styledComponent/components/icons'
import {
  getConsumerFeedbackStars,
  setFetchNewData,
  setCAPreviousSelected,
} from 'redux/actions/consumerAnalyticsActions'
import {
  CUSTOMER_SENTIMENT_TEXT,
  BRAND_AUTH_TEXT,
  SENTIMENT_VALUE,
} from '../constants'

const SentimentAndRatings = () => {
  const dispatch = useDispatch()

  const { data: selectedDate } = useSelectStore('cAnalytics.selectedDate')
  const { data: shouldFetchNewData } = useSelectStore(
    'cAnalytics.previousSelected.shouldFetchNewData'
  )
  const { data: selectedStores } = useSelectStore('stores.selectedStores')
  const fetchAction = () => getConsumerFeedbackStars({}, { loader: true })

  const {
    data: feedbackStars,
    loading,
    fetchData,
  } = useStoreFetch(fetchAction, 'cAnalytics.feedbackStars', true)

  React.useEffect(() => {
    const fetchDataAndCheckPreviousSelected = async (_) => {
      if (shouldFetchNewData) {
        fetchData()
        await dispatch(setFetchNewData(false))
      }

      await dispatch(setCAPreviousSelected({ selectedDate, selectedStores }))
    }

    fetchDataAndCheckPreviousSelected()
  }, [selectedDate, selectedStores, shouldFetchNewData])

  const formatChartData = (data) => {
    const formattedValues = []
    if (!data || !Array.isArray(data)) return formattedValues

    const totalCount = data.reduce((acc, curr) => {
      return (acc = acc + curr.ratingCount)
    }, 0)

    data.forEach(({ ratingCount, stars }) => {
      formattedValues.push({
        label: stars,
        value: ratingCount / totalCount,
      })
    })
    return formattedValues
  }

  const formatForSentimentData = (data) => {
    const formattedValues = {}
    if (!data || !Array.isArray(data)) return formattedValues
    const happyStars = [5]
    const moderateStars = [4, 3]
    const sadStars = [2, 1]

    data.forEach(({ ratingCount, stars }) => {
      if (happyStars.includes(stars)) {
        formattedValues.happy = ratingCount
      }

      if (moderateStars.includes(stars)) {
        formattedValues.moderate = formattedValues.moderate
          ? formattedValues.moderate + ratingCount
          : ratingCount
      }

      if (sadStars.includes(stars)) {
        formattedValues.sad = formattedValues.sad
          ? formattedValues.sad + ratingCount
          : ratingCount
      }
    })

    return formattedValues
  }

  return (
    <>
      <SectionHeading
        {...CUSTOMER_SENTIMENT_TEXT}
        variant="p"
        subVariant="small"
        color="main"
        weight="bold"
      />
      {loading ? (
        <Skeleton count={1} height="150px" />
      ) : (
        <SentimentStats stats={formatForSentimentData(feedbackStars)} />
      )}

      <SectionHeading
        {...BRAND_AUTH_TEXT}
        variant="p"
        subVariant="small"
        color="main"
        weight="bold"
      />
      {loading ? (
        <Skeleton count={5} />
      ) : (
        <Histogram
          width={'98%'}
          data={formatChartData(feedbackStars)}
          color="#fedc64"
          endIcon={<NewStarIcon />}
        />
      )}
    </>
  )
}

export default SentimentAndRatings

const SentimentStats = ({ stats }) => {
  return (
    <StatsList>
      {SENTIMENT_VALUE.map((item, index) => (
        <StatItem key={index}>
          <item.image />
          <StatTitle>
            <Text variant="medium" align="center">
              {item.type}
            </Text>
            <Text variant="medium" align="center">
              {stats[item.type]}
            </Text>
          </StatTitle>
        </StatItem>
      ))}
    </StatsList>
  )
}

const StatsList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 25px 10px 10px;
  align-items: flex-end;
`

const StatItem = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  svg {
    margin: 0 auto;
  }
`

const StatTitle = styled.span`
  text-transform: capitalize;
  font-weight: 900;
  padding: 10px 0;
  span {
    display: block;
  }
`
