/**
 * @description Helper util to create reducer dynmically with action types and initial state
 * @param initialState - initial state of the reducer.
 * @example
 * import { createReducer } from 'utils/reducerUtil'
 * import * as types from 'your-types-export-file'
 * const initialState = {}
 * createReducer(initialState)(types)
 */
export default (initialState = {}) =>
  /**
   * @param types - all action types exports
   * @return Function
   */
  (types) =>
  (state = initialState, action) => {
    const typeKeys = typeof types === 'string' ? [types] : Object.values(types)
    for (let i = 0; i < typeKeys.length; i += 1) {
      if (action.type === typeKeys[i]) {
        return {
          ...state,
          ...action.payload,
        }
      }
    }

    return state
  }
