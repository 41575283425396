const initialState = {
  campaigns: [],
  // billmeCount: 0,
  listCount: 0,
  campaignsCount: 0,
  smsStatus: false,
  companyId: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_BILLME_NUMBERS_COUNT':
      return {
        ...state,
        billmeCount: action.payload,
      }
    case 'FETCH_SMS_CAMPAIGNS':
      return {
        campaigns: action.payload.initialReq
          ? action.payload.data
          : state.campaigns.concat(action.payload.data),
        campaignsCount: action.payload.initialReq
          ? action.payload.campaignsCount
          : state.campaignsCount,
      }

    case 'GET_SMS_REPORT':
      return {
        ...state,
        smsStatus: action.payload,
      }

    case 'SET_COMPANY_ID':
      return {
        ...state,
        companyId: action.payload,
      }
    default:
      return state
  }
}
