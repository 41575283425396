import React, { Component } from 'react'
import { FormattedNumber } from 'react-intl'
import shareIcon from '../../../../images/share.svg'
import emailIcon from 'assets/icons/email-white.svg'
import phoneIcon from 'assets/icons/phone-white.svg'
import { getStoreAddress } from '../../../../redux/actions/bills'
import { formatDateWithTime } from '../../../../utils/getDateFormat'
import ToggleSwitch from '../../../Common/ToggleSwitch'
import {
  DetailsHeaderImage,
  // CustomerAndBillInfoChild,
  // ResendButton,
  // CustomerAndBillInfo,
  DetailsHeader,
} from '../style'
import { FlexBox } from 'styledComponent'
import { Text } from 'components/Common/Text'
import { spacing } from 'design-system'
import { connect } from 'react-redux'
import {
  cinepolisVendorId,
  mirajVendorId,
  connplexVendorId,
  nyCinemasVendorId,
  rajhansId,
  movieMaxVendorId,
} from '../../../conditionalComponent'

import { getBookingId } from '../../../../utils/billColumnHelper'

class BillDetailsLeft extends Component {
  constructor() {
    super()
    this.state = {
      storeAddress: '',
    }
  }
  async componentDidMount() {
    let res = await getStoreAddress({
      storeId: this.props.billData.storeId,
    })
    if (res.code) {
      this.setState({
        storeAddress: res.data,
      })
    }
  }
  render() {
    const {
      brandName,
      brandLogo,
      billData = {},
      countryCode: configCountryCode,
      currencyCode,
    } = this.props.billData.other
    const {
      user,
      billUid,
      createdDate,
      amount,
      transactionType,
      shortId,
      email,
      parsedData,
      vendorId,
    } = this.props.billData

    const { date, time } = formatDateWithTime(new Date(createdDate))

    let countryCode =
      (vendorId === 'j6cugjkr688xu4'
        ? '94'
        : `${
            parsedData?.customerData?.phoneDialCode || configCountryCode || '91'
          }`) + '-'

    countryCode = !countryCode.includes('+') ? `+${countryCode}` : countryCode
    const showBookingId =
      [
        cinepolisVendorId,
        mirajVendorId,
        connplexVendorId,
        nyCinemasVendorId,
        rajhansId,
        movieMaxVendorId,
      ].includes(vendorId) &&
      parsedData?.customData?.ticketType === 'CINEPOLIS_TICKET'
    return (
      <FlexBox direction="column" height="100%" justify="space-between">
        <div style={{ overflow: 'auto', height: '450px', minHeight: 0 }}>
          <DetailsHeader>
            <DetailsHeaderImage
              src={brandLogo}
              style={{ position: 'sticky', top: 0 }}
            />
            <div>
              <Text
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#f2f5f8',
                }}
                variant="p"
                weight="semi-bold"
              >
                {brandName}
              </Text>
              <Text variant="medium">{this.state.storeAddress}</Text>
            </div>
          </DetailsHeader>
          <FlexBox
            justify="center"
            align="center"
            margin={`0 ${spacing.l}`}
            style={{ borderBottom: '1px solid #707070' }}
          >
            <div className="customerAndBillInfo_billInfo">
              <div className="customerAndBillInfo_billInfo_left customerAndBillInfo_billInfo_child">
                <div>Contact No:</div>
                <div>Email:</div>
                <div>Invoice No:</div>
                <div>Bill UID:</div>
                {showBookingId ? <div>Booking ID:</div> : null}

                <div>Date:</div>
                <div>Time:</div>
                <div>Amount:</div>
                <div>Type of trans:</div>
                <div>Bill link:</div>
              </div>
              <div className="customerAndBillInfo_billInfo_right customerAndBillInfo_billInfo_child">
                <div>{user ? countryCode + user : ' - '}</div>
                <div>{email || ' - '}</div>
                <div>{parsedData?.invoiceNumber || ' - '}</div>
                <div>{billUid}</div>
                {showBookingId ? (
                  <div>
                    {getBookingId(
                      this.props.billData?.parsedData?.customData?.barcode
                    )}
                  </div>
                ) : null}
                <div>{date}</div>
                <div>{time}</div>
                <div>
                  {amount ? (
                    <FormattedNumber
                      style="currency"
                      currency={
                        parsedData?.billAmountData?.saleCurrency ||
                        currencyCode ||
                        this.props.currencyCode
                      }
                      value={amount}
                    />
                  ) : (
                    ' - '
                  )}
                </div>

                <div>
                  {transactionType === 'BM'
                    ? 'BillMe'
                    : transactionType === 'BMPrint'
                      ? 'BillMe + Print'
                      : transactionType === 'Print'
                        ? 'Print'
                        : 'Closed Window'}
                </div>
                <div>
                  <a href={this.props.billLink} target="_blank">
                    Click to Open bill
                  </a>
                </div>
              </div>
            </div>
          </FlexBox>
        </div>
        <div className="resendButton billDetailsLeftResend">
          <div
            onClick={() => {
              this.props.onResend()
            }}
          >
            <img src={shareIcon} alt="" /> Resend Bill
          </div>
          {!this.props.isCashier && (
            <ToggleSwitch
              startIcon={phoneIcon}
              endIcon={emailIcon}
              startText="Phone"
              endText="Email"
              isActive={this.props.resendOnEmail}
              onChange={this.props.toggleResendDestination}
            />
          )}
        </div>
      </FlexBox>
    )
  }
}

const mapStateToProps = (state) => ({
  currencyCode: state.auth.user.currencyCode,
})

export default connect(mapStateToProps)(BillDetailsLeft)
