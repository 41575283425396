import React, { Component } from 'react'
import InputHelper from './InputHelper'

class StoreAddPopupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
    }
    this.onChange2 = this.onChange2.bind(this)
    this.openEdit = this.openEdit.bind(this)
  }
  onChange2(field, value) {
    this.setState({ [field]: value })
  }
  openEdit() {
    this.setState({ disabled: false })
  }
  render() {
    return (
      <div className="storePopupModal">
        <div className="popupModalHeading">
          <span className="popupModalHeadingInner">Add Store</span>
        </div>
        <div className="popupModalGrids">
          <div className="popupModalGridInner">
            <InputHelper
              label="Store Incharge"
              type="text"
              name="storeIncharge"
              placeholder="Store Incharge"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Store Contact"
              type="text"
              name="storeContact"
              placeholder="Store Contact"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Select Brand"
              type="select"
              name="storeBrand"
              placeholder="select Brand"
              value="MacD"
              optionValues={[
                { name: 'MacD', value: 'MacD' },
                { name: 'Dominos', value: 'Dominos' },
              ]}
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Start Time"
              type="time"
              name="storeStartTime"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="End Time"
              type="time"
              name="storeEndTime"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Select Company"
              type="select"
              name="storeCompany"
              placeholder="select Company"
              value="JHKP Solutions"
              optionValues={[
                { name: 'ABC Internationl', value: 'ABC Internationl' },
                { name: 'JHKP Solutions', value: 'JHKP Solutions' },
              ]}
              onChange={this.onChange2}
              inputClassName="form-control"
            />
          </div>
          <div className="popupModalGridInner">
            <InputHelper
              label="Store Address"
              type="textarea"
              name="storeAddress"
              value=""
              placeholder="Store Address"
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Store Display Address"
              type="text"
              name="displayAddress"
              value=""
              placeholder="Store Display Address"
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Number Of POS"
              type="number"
              name="storePosNumbers"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Number Of Employees"
              type="number"
              name="storeEmployees"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
            <InputHelper
              label="Store Size"
              type="number"
              name="storeSize"
              value=""
              onChange={this.onChange2}
              inputClassName="form-control"
            />
          </div>
        </div>
        <button className="popupModalSubmitButton">Request</button>
      </div>
    )
  }
}

export default StoreAddPopupModal
