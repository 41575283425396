import React, { Component } from 'react'

class RefundContent extends Component {
  render() {
    return (
      <div className="refundContent">
        <strong>Refund and Cancellation Policy</strong>
        <br /> Our focus is complete customer satisfaction. In the event, if you
        are displeased with the services provided, we will do our best to make
        them better for you.
        <br /> <br /> In case of dissatisfaction from our services, clients have
        the liberty to cancel their accounts. Our Policy for the cancellation
        and refund will be as follows:
        <br />
        <br />
        <strong>Cancellation Policy</strong>
        <br /> Once a Vendor partner is registered with BillMe (JHKP
        TECHNOLOGIES PVT LTD), they can request account deletion. The vendor
        partner must allow BillMe with 60 working days for total account and
        data deletion. <br /> <br /> <strong>Refund Policy</strong>
        <br /> We will try our best to create the suitable design concepts for
        our clients. Once a vendor Partner recharges their BillMe account, they
        cannot ask for a refund as the transactional SMS have been alloted for
        that acoount. The vendor partner can still request for cancellation
        without a refund.
      </div>
    )
  }
}

export default RefundContent
