import React, { Component } from 'react'
import InputHelper from './InputHelper'
import { connect } from 'react-redux'

class StorePopupModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: true,
    }
    this.onChange2 = this.onChange2.bind(this)
    this.openEdit = this.openEdit.bind(this)
  }
  onChange2(field, value) {
    this.setState({ [field]: value })
  }
  openEdit() {
    this.setState({ disabled: false })
  }
  render() {
    return (
      <div className="storePopupModal">
        <div className="popupModalHeading">
          <span className="popupModalHeadingInner">Store Details</span>
          {/* <span className="popupModalEditButton" onClick={this.openEdit}>
            {this.state.disabled ? (
              <img src={require("./images/Icon.png")} />
            ) : null}
          </span> */}
        </div>
        <div className="popupModalGrids">
          <div className="popupModalGridInner">
            <InputHelper
              label="Store ID"
              type="text"
              name="storeId"
              value={this.props.stores[this.props.pID].storeId}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={true}
            />
            <InputHelper
              label="Store incharge"
              type="text"
              name="storeIncharge"
              value={this.props.stores[this.props.pID].storeInCharge}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Store Contact"
              type="text"
              name="storeContact"
              value={this.props.stores[this.props.pID].storeContact}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Display Address"
              type="text"
              name="storeDisplayAddress"
              value={this.props.stores[this.props.pID].displayAddress}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Address"
              type="textarea"
              name="storeAddress"
              value={this.props.stores[this.props.pID].address}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
          </div>
          <div className="popupModalGridInner">
            <InputHelper
              label="Start Time"
              type="time"
              name="storeStartTime"
              value={this.props.stores[this.props.pID].storeTime.start}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="End Time"
              type="time"
              name="storeEndTime"
              value={this.props.stores[this.props.pID].storeTime.end}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Number Of POS"
              type="number"
              name="storePosNumbers"
              value={this.props.stores[this.props.pID].noOfPos}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={true}
            />
            <InputHelper
              label="Number Of Employees"
              type="number"
              name="storeEmployees"
              value={this.props.stores[this.props.pID].noOfEmployee}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
            <InputHelper
              label="Store Size"
              type="number"
              name="storeSize"
              value={this.props.stores[this.props.pID].storeSize}
              onChange={this.onChange2}
              inputClassName="form-control"
              disabled={this.state.disabled}
            />
          </div>
        </div>
        {!this.state.disabled ? (
          <button className="popupModalSubmitButton">Update</button>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  stores: state.storesBrandsCompanies.stores,
})

export default connect(mapStateToProps)(StorePopupModal)
