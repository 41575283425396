import React, { Component } from 'react'

var SelectOptions
class InputHelper extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.state = {
      [this.props.name]: this.props.value,
    }
    if (this.props.type === 'select') {
      SelectOptions = () =>
        this.props.optionValues.map((data) => {
          return (
            <option value={data.value} key={data.value}>
              {data.name}
            </option>
          )
        })
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    const fieldName = e.target.name
    const fieldValue = e.target.value
    this.props.onChange(fieldName, fieldValue)
  }
  render() {
    return (
      <div>
        <div className="popupModalLabel">{this.props.label}</div>
        {this.props.type === 'textarea' ? (
          <textarea
            name={this.props.name}
            value={this.state[this.props.name]}
            onChange={this.onChange}
            className={this.props.inputClassName}
            rows="3"
            disabled={this.props.disabled}
          />
        ) : this.props.type === 'select' ? (
          <select
            name={this.props.name}
            value={this.state[this.props.name]}
            onChange={this.onChange}
            className={this.props.inputClassName}
            disabled={this.props.disabled}
            required={this.props.isRequired}
          >
            <SelectOptions />
          </select>
        ) : (
          <input
            type={this.props.type}
            placeholder={this.props.placeholder}
            name={this.props.name}
            value={this.state[this.props.name]}
            onChange={this.onChange}
            className={this.props.inputClassName}
            disabled={this.props.disabled}
            required={this.props.isRequired}
            min={this.props.min}
          />
        )}
      </div>
    )
  }
}

export default InputHelper
