import React, { Component } from 'react'
import { connect } from 'react-redux'
import BillDetailsLeft from './BillDetailsLeft'
import BillDetailsRight from './BillDetailsRight'
import BillResendLeft from './BillResendLeft'
import { userTypes } from 'components/Layout/constants'
import { $BlueBox } from 'components/Content/autoEngagement/AutoEngagement.styled'
import { TopActionBar } from 'components/Common'
import { Div } from 'styledComponent'
import { get } from 'utils/axiosHandler'

class BillDetails extends Component {
  constructor() {
    super()
    this.state = {
      isResend: false,
      user: '',
      resendOnEmail: false,
      email: '',
      billLink: '',
    }
    this.handleGetNumber = this.handleGetNumber.bind(this)
    this.handleOnResend = this.handleOnResend.bind(this)
  }

  handleGetNumber(data) {
    this.setState({
      user: data,
    })
  }

  handleOnResend() {
    this.setState({
      isResend: true,
    })
  }

  toggleResendDestination = () => {
    this.setState((state) => ({
      ...state,
      resendOnEmail: !state.resendOnEmail,
    }))
  }

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  componentDidMount() {
    get(
      '/bills/signed',
      {
        billUid: this.props.billData.shortId,
      },
      {
        headers: {
          'vendor-id': this.props.selectedVendor?.value,
        },
      }
    ).then((res) => {
      this.setState({ billLink: `https://${res?.bills?.[0]?.billUrl}` })
    })
  }

  render() {
    const actProps = this.state.isResend
      ? {
          onExit: () => this.setState({ isResend: false }),
          exitText: 'Go Back',
        }
      : {}
    return (
      <>
        <Div pb="12px">
          <TopActionBar
            hideTopBottomLine
            title="Bill Information"
            {...actProps}
          />
        </Div>
        <$BlueBox width="100%">
          <div className="billsPopupContent">
            <div className="leftPart">
              {this.state.isResend ? (
                <>
                  <BillResendLeft
                    getNumber={this.handleGetNumber}
                    isCashier={this.props.isCashier}
                    user={this.state.user}
                    userPhone={this.props.billData.user}
                    resendOnEmail={this.state.resendOnEmail}
                    onEmailChange={this.onEmailChange}
                    email={this.state.email}
                    brandName={this.props.billData.other.brandName}
                    shortId={this.props.billData.shortId}
                  />
                </>
              ) : (
                <BillDetailsLeft
                  onResend={this.handleOnResend}
                  billData={this.props.billData}
                  toggleResendDestination={this.toggleResendDestination}
                  resendOnEmail={this.state.resendOnEmail}
                  isCashier={this.props.isCashier}
                  billLink={this.state.billLink}
                />
              )}
            </div>
            <div className="rightPart">
              <BillDetailsRight billLink={this.state.billLink} />
            </div>
          </div>
        </$BlueBox>
      </>
    )
  }
}

const mapStatesToProps = (state) => ({
  isCashier: state.auth.user.userType === userTypes.CASHIER,
  isManager: state.auth.user.userType === userTypes.MANAGER,
  selectedVendor: state.vendorIds.selectedVendor,
})

export default connect(mapStatesToProps)(BillDetails)
