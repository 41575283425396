import React from 'react'

export function FilterHeader(props) {
  return (
    <div className="filter-modal">
      <div className="filter-modal-head">
        <h5 className="header">{props.heading}</h5>
      </div>
    </div>
  )
}
