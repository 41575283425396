import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    html, body, #root{
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
    }
`
