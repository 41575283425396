import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'
import { useTheme } from 'styled-components'

import { Div, FlexBox } from 'styledComponent'
import { Text, Button, Input, useToasts, LoaderPopup } from 'components/Common'
import { CloseIcon } from 'assets/icons'
import { $Browse, $PopContainer } from '../AutoEngagement.styled'
import {
  ERROR_DECODE,
  POPUP_STYLE,
  UPLOAD_CSV_INSTRUCTIONS,
} from '../constants'
import {
  setCampaignLoader,
  uploadCsvData,
  uploadFiles,
} from 'redux/actions/autoEngageActions'
import { UploadBox } from './MediaUpload'

const UploadData = ({
  isPopOpen,
  setIsPopOpen,
  setTargetGroupId,
  fetchAssets,
  fileType,
  acceptedFormats,
  uploadedFrom,
  setCampaignLoader,
  aeLoading,
  ...props
}) => {
  const { vendorId, storeUser } = props
  const { toast } = useToasts()
  const { spacing } = useTheme()
  const [form, setForm] = useState({})
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    })
  }

  const handleFileUpload = (e) => {
    setForm({
      ...form,
      file: e.target.files[0],
    })
  }

  const onImportData = async () => {
    setCampaignLoader(true)
    try {
      if (!form?.name) throw { message: `Name shouldn't be empty` }
      if (!form?.file)
        throw { message: `Please upload a ${fileType || ''} file` }

      const formData = new FormData()
      formData.append('vendorId', vendorId)
      formData.append('storeUser', storeUser)
      formData.append('name', form.name)
      form.description && formData.append('description', form.description)
      formData.append('file', form.file)
      formData.append('uploadedFrom', uploadedFrom)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      const res =
        getFileType() === 'csv'
          ? await uploadCsvData(formData, config)
          : await uploadFiles(formData, config)
      if (res?.data || res?._id) {
        fetchAssets && (await fetchAssets())
        if (res?.data?._id && setTargetGroupId)
          setTargetGroupId({ value: res.data._id })
        toast('File Uploaded successfully', 'success')
        setIsPopOpen(false)
        setForm({})
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      setCampaignLoader(false)
    }
  }

  const onCancel = () => {
    setForm({})
    setIsPopOpen(false)
  }

  const uploadedFileReader = (file) => {
    if (file) {
      return URL.createObjectURL(file)
    } else return ''
  }

  const getFileType = () => {
    if (fileType) return fileType
    const uploadedFile = form.file
    const uploadedFileType =
      uploadedFile?.name?.split('.')[uploadedFile?.name?.split('.')?.length - 1]
    return uploadedFileType
  }

  const isCsvFileType = getFileType() === 'csv'

  const csvCssProps = isCsvFileType
    ? {
        borderRight: '1px solid #e6e6e6',
        mr: spacing.l,
        pr: spacing.l,
      }
    : { width: '100%' }

  return (
    <Popup
      open={isPopOpen}
      onClose={onCancel}
      contentStyle={{
        ...POPUP_STYLE,
        width: isCsvFileType ? '1000px' : '800px',
      }}
      className="modal"
    >
      <LoaderPopup isPopOpen={aeLoading} />
      <$PopContainer>
        <FlexBox justify="space-between">
          <Text variant="p" weight="bold" type="main">
            Upload New Data
          </Text>
          <CloseIcon type="button" onClick={() => setIsPopOpen(false)} />
        </FlexBox>
        <FlexBox>
          <Div {...csvCssProps}>
            {isCsvFileType && (
              <FlexBox justify="space-between" mb={spacing.m}>
                <Text variant="medium" type="main">
                  Upload .csv file for a list of users
                </Text>
                <Button
                  fontSize="12px"
                  variant="primaryOutline"
                  onClick={() =>
                    window.open('https://assets.billme.co.in/crm/sample.csv')
                  }
                >
                  Download Sample .csv
                </Button>
              </FlexBox>
            )}
            <UploadBox
              src={uploadedFileReader(form.file)}
              uploadWidth="100%"
              uploadHeight="150px"
              acceptedFormats={acceptedFormats}
              fileType={getFileType()}
              onChange={handleFileUpload}
              displayFileType="file"
            />
            <$Browse>
              <span>Upload File : </span>
              <div>
                <input
                  type="file"
                  title={form?.file?.name || 'No File Chosen'}
                  onChange={handleFileUpload}
                  accept={acceptedFormats}
                />
                <small>BROWSE</small>
              </div>
            </$Browse>
            <Input
              containerMargin="0"
              variant="input"
              label="Uploaded Data File Name"
              placeholder="Enter Data File Name"
              id="name"
              value={form.name || ''}
              onChange={handleChange}
              inputWidth="400px"
            />
            <Input
              containerMargin="0"
              variant="textarea"
              rows={3}
              label="Uploaded Data File Description"
              placeholder="Describe the data file"
              id="description"
              value={form.description || ''}
              onChange={handleChange}
              inputWidth="500px"
            />
          </Div>
          {isCsvFileType && (
            <Div>
              <Text variant="p" type="main" weight="bold" mb={spacing.m}>
                Instructions
              </Text>
              {UPLOAD_CSV_INSTRUCTIONS.map((inst) => (
                <Text key={inst} variant="medium" type="main" mb={spacing.s}>
                  {inst}
                </Text>
              ))}
            </Div>
          )}
        </FlexBox>
        <FlexBox justify="flex-end">
          <Button variant="primary" onClick={onImportData}>
            Import Data
          </Button>
        </FlexBox>
      </$PopContainer>
    </Popup>
  )
}

const mapStateToProps = (state) => ({
  storeUser: state.auth.user.userId,
  vendorId: state.vendorIds.selectedVendor.value,
  aeLoading: state.autoEngage.aeLoading,
})

export default connect(mapStateToProps, { setCampaignLoader })(UploadData)
