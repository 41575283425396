import styled from 'styled-components'

export const Progress = styled.div`
  width: 100%;
  position: relative;
`

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  width: ${(props) => props['width'] || 0};
  background-color: ${(props) =>
    props['color'] || props.theme.colors.primary['400']};
`

export const ProgressOutline = styled.div`
  display: -ms-flexbox;
  display: flex;
  height: 9px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 17px;
`

export const Labels = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`

export const Label = styled.div`
  ${Labels} & {
    width: ${(props) => props['width'] || `100%`};
    min-width: 46px;
    color: #000000;
    font-size: 12px;
    position: relative;
    top: 15px;
    padding-left: 5px;
  }
`
